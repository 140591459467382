import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import AlexisIcon from '../assets/images/integrations/alexisHR.svg'
import APIWhiteIcon from '../assets/images/integrations/api-white.svg'
import APIPurple from '../assets/images/integrations/api-purple.svg'
import BambooIcon from '../assets/images/integrations/bamboo.svg'
import BreatheIcon from '../assets/images/integrations/breathe.svg'
import CharlieIcon from '../assets/images/integrations/charlie.svg'
import DayforceIcon from '../assets/images/integrations/dayforce.svg'
import FreshteamIcon from '../assets/images/integrations/freshteam.svg'
import GitHubIcon from '../assets/images/integrations/github.svg'
import GitLabIcon from '../assets/images/integrations/gitlab.svg'
import GoogleIcon from '../assets/images/integrations/google.svg'
import HiBobIcon from '../assets/images/integrations/hibob.svg'
import HRPartnerIcon from '../assets/images/integrations/HRpartner.png'
import HumaansIcon from '../assets/images/integrations/humaans.svg'
import JiraIcon from '../assets/images/integrations/jira-small.svg'
import JustworksIcon from '../assets/images/integrations/justworks.svg'
import KekaIcon from '../assets/images/integrations/keka.svg'
import LinearIcon from '../assets/images/integrations/linear-black.svg'
import LuccaIcon from '../assets/images/integrations/lucca.svg'
import OfficientIcon from '../assets/images/integrations/officient.svg'
import OktaIcon from '../assets/images/integrations/okta.svg'
import PaycorIcon from '../assets/images/integrations/paycor.svg'
import PeopleIcon from '../assets/images/integrations/peopleHR.svg'
import PersonioIcon from '../assets/images/integrations/personio.svg'
import SageIcon from '../assets/images/integrations/sage.svg'
import SesameIcon from '../assets/images/integrations/sesame.svg'
import SlackIcon from '../assets/images/integrations/slack-small.svg'
import SquareIcon from '../assets/images/integrations/square.svg'
import Tag from '../assets/images/integrations/tag.svg'
import TeamsIcon from '../assets/images/integrations/teams.svg'
import TrinetIcon from '../assets/images/integrations/trinet.svg'
import UKGProIcon from '../assets/images/integrations/UKGPro.svg'
import WorkdayIcon from '../assets/images/integrations/workday.svg'
import MetaImage from '../assets/images/meta/integrations.png'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import Layout from '../components/Layout'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import { blurbArrowTransition } from '../components/system/Blurb'
import Br from '../components/system/Br'
import { LinkCard } from '../components/system/LinkCard'
import { SpaceScaleValue } from '../styles/stylePropTypes'
import { ThemeLineHeight } from '../styles/theme'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/integrations/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
  }
`

const IntegrationsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Integrations"
      variant="dark"
      isNew
      description="Get to know the integrations that help you form a complete picture of engineering effectiveness."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={Tag}
        title={
          <>
            Swarmia works with <Br />
            your tools and processes
          </>
        }
        content="Get to know the integrations that help you form a complete picture of engineering effectiveness."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading={<>Source code hosting</>}
        content="Turn data from your codebase into insights that help you deliver customer value faster."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub"
          description="Connect GitHub to track proven engineering metrics, like DORA."
          href="https://github.com/marketplace/swarmia"
        />
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub Enterprise Cloud"
          description="Integrate Swarmia with GitHub Enterprise Cloud."
          href="https://github.com/marketplace/swarmia"
        />
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub Enterprise Server"
          description="Set up Swarmia for a self-hosted GitHub Enterprise Server instance."
          href="https://help.swarmia.com/github-enterprise-server"
        />
        <IntegrationBlurb
          icon={GitLabIcon}
          name="GitLab"
          description="Measure a wide variety of software development metrics."
          href="https://about.gitlab.com"
          comingSoon
        />
      </IntegrationGrid>
      <LeadBlock
        heading={<>Issue tracking</>}
        content="Connect your issue tracker to get a complete picture of planned and completed work."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={JiraIcon}
          name="Jira Software Cloud"
          description="Gain immediate visibility into all ongoing engineering work."
          href="https://marketplace.atlassian.com/apps/1222205/swarmia"
        />
        <IntegrationBlurb
          icon={JiraIcon}
          name="Jira Server & Data Center"
          description="Integrate Swarmia with your on-premise Jira instance."
          href="https://help.swarmia.com/jira-integration#jira-server-dc-integration"
        />
        <IntegrationBlurb
          icon={LinearIcon}
          name="Linear"
          description="Get full visibility into the progress of your projects and initiatives."
          href="https://linear.app/integrations/swarmia"
        />
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub Issues"
          description="Get your projects, issues, and  tasks through faster."
          href="https://github.com/features/issues"
          comingSoon
        />
      </IntegrationGrid>
      <LeadBlock
        heading={<>Messaging</>}
        content="Speed up developer workflows and automate feedback loops by connecting your messaging system."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={SlackIcon}
          name="Slack"
          description="Speed up development workflows without leaving Slack."
          href="https://slack.com/marketplace/ATCSZBG06-swarmia"
        />
        <IntegrationBlurb
          icon={TeamsIcon}
          name="Microsoft Teams"
          description="Get your pull requests through faster with MS Teams notifications."
          href="https://www.microsoft.com/en/microsoft-teams/group-chat-software"
          comingSoon
        />
      </IntegrationGrid>
      <LeadBlock
        heading={<>Authentication & single sign-on</>}
        content="Choose the secure authentication method that works for your organization."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={GoogleIcon}
          name="Google SSO"
          description="Manage access to Swarmia through Google Workspace."
          href="https://support.google.com/a/answer/60224?hl=en"
        />
        <IntegrationBlurb
          icon={OktaIcon}
          name="Okta"
          description="Use Okta SSO to manage your organization’s access to Swarmia."
          href="https://www.okta.com/products/single-sign-on-workforce-identity"
        />
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub"
          description="Use GitHub authentication to log in to Swarmia."
          href="https://github.com/home"
        />
      </IntegrationGrid>
      <LeadBlock
        heading={<>CI/CD</>}
        content="Speed up your continuous integration pipelines and deployments by pulling data from your CI/CD system."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={APIPurple}
          name="Deployments API"
          description="Send deployment information to Swarmia from any CI/CD system."
          href="https://help.swarmia.com/sending-deployment-information-to-swarmia"
        />
        <IntegrationBlurb
          icon={GitHubIcon}
          name="GitHub Actions"
          description="Get visibility into your CI pipelines by connecting to GitHub Actions."
          href="https://github.com/features/actions"
        />
      </IntegrationGrid>
      <LeadBlock
        heading={<>Team management & data quality</>}
        content="Manage your organization’s team structure and improve data quality throughout the platform."
      />
      <IntegrationGrid>
        <IntegrationBlurb
          icon={APIWhiteIcon}
          name="Team API"
          href="https://help.swarmia.com/creating-and-managing-teams"
        />
        <IntegrationBlurb
          icon={AlexisIcon}
          name="AlexisHR"
          href="https://alexishr.com"
        />
        <IntegrationBlurb
          icon={BambooIcon}
          name="BambooHR"
          href="https://www.bamboohr.com"
        />
        <IntegrationBlurb
          icon={BreatheIcon}
          name="Breathe"
          href="https://www.breathehr.com"
        />
        <IntegrationBlurb
          icon={CharlieIcon}
          name="CharlieHR"
          href="https://www.charliehr.com"
        />
        <IntegrationBlurb
          icon={DayforceIcon}
          name="Dayforce"
          href="https://www.dayforce.com"
        />
        <IntegrationBlurb
          icon={FreshteamIcon}
          name="Freshteam"
          href="https://www.freshworks.com/freshservice/business-teams"
        />
        <IntegrationBlurb
          icon={HiBobIcon}
          name="HiBob"
          href="https://www.hibob.com"
        />
        <IntegrationBlurb
          icon={HRPartnerIcon}
          name="HR Partner"
          href="https://www.hrpartner.io"
        />
        <IntegrationBlurb
          icon={HumaansIcon}
          name="Humaans"
          href="https://humaans.io"
        />
        <IntegrationBlurb
          icon={JustworksIcon}
          name="Justworks"
          href="https://www.justworks.com/"
        />
        <IntegrationBlurb
          icon={KekaIcon}
          name="Keka"
          href="https://www.keka.com"
        />
        <IntegrationBlurb
          icon={LuccaIcon}
          name="Lucca"
          href="https://www.lucca-software.com"
        />
        <IntegrationBlurb
          icon={OfficientIcon}
          name="Officient"
          href="https://en.officient.io"
        />
        <IntegrationBlurb
          icon={PaycorIcon}
          name="Paycor"
          href="https://www.paycor.com"
        />
        <IntegrationBlurb
          icon={PeopleIcon}
          name="PeopleHR"
          href="https://www.peoplehr.com"
        />
        <IntegrationBlurb
          icon={PersonioIcon}
          name="Personio"
          href="https://www.personio.com"
        />
        <IntegrationBlurb
          icon={SageIcon}
          name="Sage HR"
          href="https://www.sage.com"
        />
        <IntegrationBlurb
          icon={SesameIcon}
          name="Sesame"
          href="https://www.sesamehr.com"
        />
        <IntegrationBlurb
          icon={SquareIcon}
          name="Square Payroll"
          href="https://squareup.com/us/en/payroll"
        />
        <IntegrationBlurb
          icon={TrinetIcon}
          name="Trinet HR"
          href="https://www.trinet.com"
        />
        <IntegrationBlurb
          icon={UKGProIcon}
          name="UKG"
          href="https://www.ukg.com/"
        />
        <IntegrationBlurb
          icon={WorkdayIcon}
          name="Workday"
          href="https://www.workday.com/"
        />
      </IntegrationGrid>
      <Box paddingTop={64} />
      <QuoteBlock person="maryanna" />
      <CTABlock title={<>Ready to see Swarmia in action?</>} />
    </Layout>
  )
}

function IntegrationGrid({ children }: { children: ReactNode }) {
  return (
    <Block>
      <Box
        display="grid"
        gridGap={32}
        gridTemplateColumns={{
          xs: 'repeat(1, minmax(0, auto))',
          sm: 'repeat(2, minmax(0, 1fr))',
          lg: 'repeat(3, minmax(0, 1fr))',
        }}
        paddingTop={64}
      >
        {children}
      </Box>
    </Block>
  )
}

function IntegrationBlurb({
  icon,
  href,
  name,
  description,
  comingSoon,
}: {
  name: string
  description?: string
  icon: string
  href: string
  comingSoon?: boolean
}) {
  return (
    <LinkCard
      href={href}
      target="_blank"
      isFullHeight
      content={
        description ? (
          <Stack
            minHeight={350}
            padding={40}
            justifyContent="space-between"
            space={16}
          >
            <Box.img src={icon} width={60} height={60} />
            <Stack space={16}>
              {comingSoon ? (
                <Box>
                  <ComingSoonBadge />
                </Box>
              ) : null}
              <Row space={8}>
                <Box.h3 font="h3">{name}</Box.h3>
                <Box
                  fontFamily="Inter"
                  fontSize={24}
                  lineHeight={'124%' as ThemeLineHeight}
                  fontWeight={700}
                  position="relative"
                  left={0}
                  className="arrow"
                  transition={blurbArrowTransition}
                >
                  ↗
                </Box>
              </Row>
              <Box font="large" paddingBottom={16}>
                {description}
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Row space={32} paddingX={40} alignItems="center" height={150}>
            <Box.img src={icon} width={60} height={60} />
            <Row space={8} alignItems="center">
              <Box.h3 font="h4">{name}</Box.h3>
              <Box
                fontFamily="Inter"
                fontSize={20}
                lineHeight={'113%' as ThemeLineHeight}
                fontWeight={700}
                position="relative"
                left={0}
                className="arrow"
                transition={blurbArrowTransition}
              >
                ↗
              </Box>
            </Row>
          </Row>
        )
      }
    />
  )
}

function ComingSoonBadge() {
  return (
    <Box
      borderRadius={9999}
      paddingX={16}
      paddingY={6 as SpaceScaleValue}
      font="badge"
      color="white"
      css={`
        background: linear-gradient(90deg, #5c599f 0%, #763980 99.86%);
      `}
      display="inline-flex"
    >
      Coming soon
    </Box>
  )
}

export default IntegrationsPage
